<template>
  <div>
    <div class="grid">
      <div class="col-12">
        <Panel>
          <template #header>
            <b>Dosya Yöneticisi</b>
          </template>
          <Toolbar>
            <template #start>
              <Button label="New Folder" icon="pi pi-plus" class="mr-2" @click="createNewFolderDialog" />
              <FileUpload choose-label="Upload" mode="basic" name="demo[]" :url="uploadUrl" :auto="true" @upload="uploadFinished" />

            </template>

          </Toolbar>
          <Splitter style="height: 500px">
            <SplitterPanel  :size="30">
              <Tree :value="folders"
                    selectionMode="single"
                    :metaKeySelection="false"
                    @nodeSelect="onNodeSelect"
                    :loading="loading"
              >

              </Tree>
              <p class="mt-3 ml-3">Seçili Klasör : </p>
              <span class="ml-3">{{ selectedFolder}}</span>
            </SplitterPanel>
            <SplitterPanel  :size="70">
              <DataTable :value="files" v-model:selection="selectedFile" selectionMode="single" dataKey="id"
                         @rowSelect="onFileSelected" responsiveLayout="scroll">
                <template #empty>
                  Dosya bulunamadı
                </template>
                <Column>
                  <template #body="slotProps">
                    <i :class="slotProps.data.icon"/> <span>{{ slotProps.data.name}}</span>
                  </template>
                </Column>
              </DataTable>
            </SplitterPanel>
          </Splitter>
        </Panel>
      </div>
    </div>
  </div>

  <Dialog style="width:500px" v-model:visible="displayNewFolderDialog">
    <template #header>
      <h3>Klasör Yaratma</h3>
    </template>
    <div class="p-fluid grid">
      <div class="field col-12 md:col-12">
    <p><label for="newFolderName">Oluşturmak İstediğiniz Klasör İsmini Girin</label></p>
    <InputText id="newFolderName" v-model="newFolderName" />
        <p class="mt-3"> {{ selectedFolder}}/{{ newFolderName }}</p>
      </div>
    </div>
    <template #footer>
      <Button label="Vazgeç" icon="pi pi-times" class="p-button-text"/>
      <Button label="Oluştur" icon="pi pi-check" @click="createNewFolder"  />
    </template>
  </Dialog>
</template>

<script>
import StorageService from "@/services/storageService";
import {createFolder, getFiles, getFolders} from "@/components/fileManager/fileResponseFunctions";

export default {
  _storageService:null,
  props: {
    rootFolder: String,
    returnField: String
  },
  emits : ['onFileSelected'],
    data() {
    return {
      folders:[],
      files:[],
      loading: false,
      selectedFolder:null,
      selectedFile:null,
      uploadUrl:"",
      displayNewFolderDialog:false,
      newFolderName:""
    }
  },
  created() {
    this._storageService = new StorageService();
    this.selectedFolder = this.rootFolder;
    this.uploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/image?path=" + this.selectedFolder;
  },
  async mounted() {
    await getFolders.call(this);
    await getFiles.call(this,this.selectedFolder );
  },
  methods:{
    async onNodeSelect(node) {
      this.selectedFolder = node.data;
      this.uploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/image?path=" + this.selectedFolder;
      await getFiles.call(this,  node.data);
    },
    onFileSelected(event) {
      this.$emit('onFileSelected', event, this.returnField);
    },
    async uploadFinished() {
      await getFiles.call(this, this.selectedFolder);
    },
    createNewFolderDialog() {
      this.displayNewFolderDialog = true;
    },
    async createNewFolder() {
      await createFolder.call(this, this.selectedFolder + "/" + this.newFolderName)
      this.displayNewFolderDialog = false;
      await getFolders.call(this);
    }
  }
}
</script>