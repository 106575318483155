<template>
  <AdminToast/>
  <div class="grid">
    <div class="col-12">
      <Panel>
        <template #header>
          <b>PDF Dosya</b>
        </template>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12">
            <label for="pdfFile">PDF Dosyası</label>
            <div class="p-inputgroup">
              <InputText id="pdfFile" type="text" v-model="dataItem.pdfFile"/>
              <Button icon="pi pi-folder-open" label="Seçin"
                      @click="openFileBrowserDetail('pdfFile')"></Button>
            </div>
          </div>

        </div>
      </Panel>

    </div>
  </div>

  <OverlayPanel ref="opDetail" appendTo="body" :showCloseIcon="true" id="overlay_panel_detail" style="width: 80%"
                :breakpoints="{'960px': '75vw'}">
    <FileManager root-folder="./uploads" :return-field="returnField"
                 @onFileSelected="onFileSelectedDetail"></FileManager>
  </OverlayPanel>
</template>

<script>
import AdminToast from "@/components/shared/toast/adminToast";
import ResourceService from "../../services/resourceService";
import FileManager from "@/components/fileManager/fileManager";

export default {
  _service: null,
  components: {AdminToast, FileManager},
  created() {
    this._service = new ResourceService();
  },
  data() {
    return {
      returnField:"",
      dataItem: {
        pdfFile:"test.pdf"
      }
    }
  },
  methods: {
    openFileBrowserDetail(fieldName) {
      this.returnField = fieldName;
      this.$refs.opDetail.toggle(event);
    },
    onFileSelectedDetail(event, fieldName) {
      console.log(this.$data["dataItem"]);
      console.log(this.$data["dataItem"][fieldName]);
      this.$data["dataItem"][fieldName] = event.data.path;
      this.$refs.opDetail.hide();
    },
  }
}
</script>